import { faPaperclip, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import { jsonToFormData, validationErrorFromAPI } from 'helpers/utils';
import { FormEvent, useEffect, useState } from 'react';
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addService, getService } from 'services/services';

interface Common {
  id: number;
  name: string;
}

interface Field {
  business_name: string;
  supplier_name: string;
  mobile_no: string;
  gst_no: string;
  pancard_no: string;
  country: string;
  state: string;
  city: string;
  address: string;
  bank_name: string;
  account_no: string;
  ifsc_code: string;
  error?: string;
}

const AddPurchasing: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [country, setCountry] = useState<Common[]>([]);
  const [supplierList, setSupplierList] = useState<any[]>([]);
  const [state, setState] = useState<Common[]>([]);
  const [city, setCity] = useState<Common[]>([]);
  const [image, setImage] = useState<File | null>(null);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  const [fields, setFields] = useState<Field>({
    business_name: '',
    supplier_name: '',
    mobile_no: '',
    gst_no: '',
    pancard_no: '',
    country: '',
    state: '',
    city: '',
    address: '',
    bank_name: '',
    account_no: '',
    ifsc_code: ''
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target; // Destructure the name and value from the event target
    setFields(prevFields => ({
      ...prevFields, // Spread the previous fields
      [name]: value // Update the specific field with the new value
    }));
  };

  const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target; // Destructure the name and value from the event target
    setFields(prevFields => ({
      ...prevFields, // Spread the previous fields
      [name]: value // Update the specific field with the new value
    }));

    if (name === 'country') {
      getState(value);
    }

    if (name === 'state') {
      getCity(value);
    }
  };

  useEffect(() => {
    getCountry();
    getSupplier();
  }, []);

  useEffect(() => {
    if (fields.state) {
      getCity(fields.state);
    }
  }, [fields.state]);

  useEffect(() => {
    if (fields.country) {
      getState(fields.country);
    }
  }, [fields.country]);

  const getSupplier = async () => {
    const response = await getService('supplier');
    console.log('response', response.data);
    setSupplierList(response.data);
  };

  const getCountry = async () => {
    const response = await getService('country');
    if (response.status === 200) {
      setCountry(response.data);
    }
  };
  const getState = async (country_id: string) => {
    const response = await getService(`state-by-country/${country_id}`);
    if (response.status === 200) {
      setState(response.data);
    }
  };
  const getCity = async (state_id: string) => {
    const response = await getService(`city-by-state/${state_id}`);
    if (response.status === 200) {
      setCity(response.data);
    }
  };

  useEffect(() => {
    if (id) {
      fetchSupplierDetails(id);
    }
  }, [id]);

  const fetchSupplierDetails = async (id: any) => {
    const response = await getService(`supplier/${id}`);
    if (response.status === 200) {
      setFields(prevFields => ({
        ...prevFields,
        business_name: response.data.business_name,
        supplier_name: response.data.supplier_name,
        mobile_no: response.data.mobile_no,
        gst_no: response.data.gst_no,
        pancard_no: response.data.pancard_no,
        country: response.data.country,
        state: response.data.state,
        city: response.data.city,
        address: response.data.address,
        bank_name: response.data.bank_name,
        account_no: response.data.account_no,
        ifsc_code: response.data.ifsc_code,
        status: 1
      }));
    } else if (response?.status === 500) {
      validationErrorFromAPI(response.data);
    }
  };

  const handleSave = async (e: FormEvent) => {
    e.preventDefault();
    const data = jsonToFormData(fields);
    let response: any = {};
    if (id) {
      response = await addService(`supplier/${id}`, data);
    } else {
      response = await addService(`supplier`, data);
    }
    if (response?.status === 200) {
      toast.success('SuccessFully Added', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      navigate('/suppliers');
    } else if (response?.status === 500) {
      toast.error('Error', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
      validationErrorFromAPI(response.data);
    }
  };

  return (
    <div>
      {/* <PageBreadcrumb items={defaultBreadcrumbItems} /> */}
      <h2 className="mb-4">{id ? 'EDIT' : 'ADD'} Purchasing</h2>

      <Form>
        <Row>
          <Col xs={12} xl={9}>
            <Row as="form" className="g-3 mb-6">
              <Col sm={6} md={4}>
                <FloatingLabel
                  controlId="floatingSelectCity"
                  label="Supplier Name"
                >
                  <Form.Select
                    name="city"
                    value={fields['city']}
                    onChange={handleSelect}
                  >
                    <option>Select Supplier</option>
                    {supplierList.length > 0 &&
                      supplierList.map(item => (
                        <option key={item.id} value={item.id}>
                          {item.supplier_name}
                        </option>
                      ))}
                  </Form.Select>
                </FloatingLabel>
              </Col>
              <Col sm={6} md={4}>
                <FloatingLabel controlId="floatingInputGrid" label="Invoice No">
                  <Form.Control
                    type="text"
                    placeholder="Business Name"
                    name="business_name"
                    value={fields['business_name']}
                    onChange={handleChange}
                  />
                </FloatingLabel>
              </Col>
              <Col sm={6} md={4}>
                <FloatingLabel
                  controlId="floatingInputGrid"
                  label="Amount W/O GST"
                >
                  <Form.Control
                    type="text"
                    placeholder="Supplier Name"
                    name="supplier_name"
                    value={fields['supplier_name']}
                    onChange={handleChange}
                  />
                </FloatingLabel>
              </Col>
              <Col sm={6} md={4}>
                <FloatingLabel controlId="floatingInputGrid" label="Discount">
                  <Form.Control
                    type="text"
                    placeholder="Mobile Numbar"
                    name="mobile_no"
                    value={fields['mobile_no']}
                    onChange={handleChange}
                  />
                </FloatingLabel>
              </Col>
              <Col sm={6} md={4}>
                <FloatingLabel controlId="floatingInputGrid" label="GST">
                  <Form.Control
                    type="text"
                    placeholder="Mobile Numbar"
                    name="mobile_no"
                    value={fields['mobile_no']}
                    onChange={handleChange}
                  />
                </FloatingLabel>
              </Col>{' '}
              <Col sm={6} md={4}>
                <FloatingLabel
                  controlId="floatingInputGrid"
                  label="Total Amount"
                >
                  <Form.Control
                    type="text"
                    placeholder="Mobile Numbar"
                    name="mobile_no"
                    value={fields['mobile_no']}
                    onChange={handleChange}
                  />
                </FloatingLabel>
              </Col>
              <Col xs={12} className="gy-6">
                <div className="d-flex justify-content-end gap-3">
                  <Link to="/purchasingListView">
                    <Button variant="phoenix-primary" className="px-5">
                      Cancel
                    </Button>
                  </Link>
                  <Button
                    variant="primary"
                    className="px-5 px-sm-15"
                    // type="submit"
                    onClick={handleSave}
                  >
                    Submit
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AddPurchasing;
